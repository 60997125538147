<template>
  <v-dialog
    v-model="show"
    max-width="600px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Índice
          <span
            class="body-2"
          >
            <i>{{ index ? 'Editando' : 'Novo' }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">

          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="typeOfApportionmentId"
              >
                <v-select
                  v-model="fields.typeOfApportionmentId"
                  :items="typesOfApportionments"
                  item-value="id"
                  item-text="description"
                  label="Tipo"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="year"
              >
                <v-text-field
                  v-model="fields.year"
                  v-mask="'####'"
                  label="Ano"
                  :error-messages="errors"
                  hide-details="auto"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="index"
              >
                <v-text-field
                  v-model="fields.index"
                  v-mask="'#,##########'"
                  label="Índice"
                  :error-messages="errors"
                  hide-details="auto"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon
            left
            v-text="'mdi-content-save'"
          />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import citiesApi from '@/api/cities'
  import typesOfApportionmentsApi from '@/api/types-of-apportionments'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      cityId: {
        type: Number,
      },
      index: {
        type: Object,
        default: () => { },
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        typesOfApportionments: [],
        fields: {}
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    watch: {
      show (val) {
        if (!val) return

        this.load()
      },
    },

    methods: {

      async load () {
        try {
          this.loading = true

          if (this.$refs.observer) {
            this.$refs.observer.reset()
          }

          this.fields = { ...this.index } || {}
          this.typesOfApportionments = []

          const responseTypesOfApportionments = await typesOfApportionmentsApi.list()

          this.typesOfApportionments = responseTypesOfApportionments.data.typesOfApportionments
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async save () {
        this.loadingSave = true
        this.$refs.observer.reset()

        try {
          const fields = { ...this.fields }

          fields.index = fields.index ? parseFloat(fields.index.replace(',', '.')) : null

          if (this.index) {
            await citiesApi.updateTypeOfApportionment(this.cityId, this.index.id, fields)
          } else {
            await citiesApi.insertTypeOfApportionment(this.cityId, fields)
          }

          this.$snackbar.show({ color: 'success', message: 'Salvo com sucesso' })
          this.show = false
          this.$emit('save')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loadingSave = false
        }
      },

    },

  }
</script>
